<template>
  <div>

    <app-collapse
      accordion
      type="margin"
    >
      <app-collapse-item title="Import From Single Link">
        <b-input-group>
          <b-form-input
            v-model="link"
            placeholder="Crunchbase Link"
          />
          <b-input-group-append>
            <b-button
              :disabled="!link || loading"
              variant="outline-primary"
              @click="importDataFromLink"
            >
              <template v-if="loading">
                <b-spinner small />
                Importing...
              </template>
              <template v-else>
                Import
              </template>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </app-collapse-item>
    </app-collapse>

    <b-row
      v-if="hunter && hunter.requests && hunter.requests.searches && hunter.requests.verifications"
      class="match-height mt-2"
    >
      <b-col
        xl="12"
        md="12"
      >
        <ecommerce-statistics
          :data="[{
                    icon: 'SearchIcon',
                    color: 'light-primary',
                    title: `${hunter.requests.searches.used}/${hunter.requests.searches.available}`,
                    subtitle: 'Searches',
                    customClass: 'mb-2 mb-xl-0',
                  },
                  {
                    icon: 'ActivityIcon',
                    color: 'light-info',
                    title: `${hunter.requests.verifications.used}/${hunter.requests.verifications.available}`,
                    subtitle: 'Verifications',
                    customClass: 'mb-2 mb-xl-0',
                  }]"
          title="Hunter"
          :subtitle="hunter.reset_date"
        />
      </b-col>
    </b-row>

    <div style="position: relative;">
      <b-card
        no-body
        class="mb-0 mt-4"
      >

        <div
          style="position: absolute; right: 0; top: -35px; font-weight: bold; font-size: 16px;"
          class="text-right"
        >
          #{{ pagination.totalItems || 0 }}
        </div>

        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="mainPerPage"
                dir="ltr"
                :options="[10, 20, 50, 100]"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model.lazy="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                  @input="searchName"
                />
              </div>
            </b-col>
          </b-row>
        </div>

        <div class="m-2">
          <b-row>
            <b-col
              cols="6"
            >
              <b-button
                v-if="selected && selected.length"
                variant="primary"
                @click="applySelectedCompanies"
              >
                <span class="text-nowrap">Apply Selected Companies</span>
              </b-button>
            </b-col>
            <b-col
              v-if="inviteSelected && inviteSelected.length"
              cols="6"
              class="text-right"
            >
              <!-- <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="mr-2"
                variant="outline-primary"
                @click="syncCompanies"
              >
                <feather-icon
                  icon="RefreshCwIcon"
                  class="mr-50"
                />
                <span class="align-middle">SYNC</span>
              </b-button> -->

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                @click="sendCompaniesInvitations"
              >
                <feather-icon
                  icon="NavigationIcon"
                  class="mr-50"
                />
                <span class="align-middle">Send Invitations</span>
              </b-button>
            </b-col>

          </b-row>
        </div>

        <b-table
          ref="selectableTable"
          class="position-relative"
          :items="companies"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :busy="isLoading"
          :sort-by.sync="attribute"
          :sort-desc.sync="isDesc"
          :sort-compare="sortCompare"
          empty-text="No matching records found"
        >
          <template #cell(show_details)="row">
            <b-form-checkbox
              v-if="row.item.huntedUsers && row.item.huntedUsers.length"
              v-model="row.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="row.toggleDetails"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                </span>
              </span>
              <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
            </b-form-checkbox>
            <span v-else>N/A</span>
          </template>

          <template #row-details="row">
            <b-card>
              <b-table
                small
                :fields="fields"
                :items="row.item.huntedUsers"
                responsive="sm"
              >
                <template #cell(name)="data">
                  {{ data.item.name || 'N/A' }}
                </template>

                <template #cell(email)="data">
                  <b-button
                    v-if="data.item.email"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    v-b-tooltip.hover.v-success
                    v-clipboard:copy="data.item.email"
                    v-clipboard:success="onCopy"
                    variant="gradient-success"
                    class="btn-icon rounded-circle"
                    :title="data.item.email"
                  >
                    <feather-icon icon="MailIcon" />
                  </b-button>
                  <span v-else>N/A</span>
                </template>

                <template #cell(phone)="data">
                  <b-button
                    v-if="data.item.phone"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    v-b-tooltip.hover.v-info
                    v-clipboard:copy="data.item.phone"
                    v-clipboard:success="onCopy"
                    variant="gradient-info"
                    class="btn-icon rounded-circle"
                    :title="data.item.phone"
                  >
                    <feather-icon icon="PhoneCallIcon" />
                  </b-button>
                  <span v-else>N/A</span>
                </template>

                <template #cell(twitter)="data">
                  <b-button
                    v-if="data.item.twitter"
                    class="btn-icon is-twitter rounded-circle"
                    style="margin: 0 1px;"
                    @click="openExternalLink(data.item.twitter)"
                  >
                    <feather-icon icon="TwitterIcon" />
                  </b-button>
                  <span v-else>N/A</span>
                </template>

                <template #cell(linkedin)="data">
                  <b-button
                    v-if="data.item.linkedin"
                    class="btn-icon is-linkedin rounded-circle"
                    style="margin: 0 1px;"
                    @click="openExternalLink(data.item.linkedin)"
                  >
                    <feather-icon icon="LinkedinIcon" />
                  </b-button>
                  <span v-else>N/A</span>
                </template>

                <template #cell(position)="data">
                  {{ data.item.position || 'N/A' }}
                </template>
              </b-table>

              <b-button
                size="sm"
                variant="outline-secondary"
                @click="row.toggleDetails"
              >
                Hide Details
              </b-button>
            </b-card>
          </template>

          <template #cell(id)="data">
            <b-form-checkbox
              v-model="selected"
              :value="data.item.id"
              plain
              class="vs-checkbox-con"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                </span>
              </span>
            </b-form-checkbox>
          </template>

          <template #cell(name)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.logo"
                  :text="avatarText(data.item.name)"
                  :to="{ name: 'company-info', params: { slug: data.item.slug } }"
                  @mouseenter.native="previewPhoto($event, data.item.logo)"
                  @mouseleave.native="previewPhoto($event, null)"
                />
              </template>
              <b-link
                :to="{ name: 'company-info', params: { slug: data.item.slug } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.name }}
              </b-link>
              <small class="text-muted">@{{ data.item.slug }}</small>
            </b-media>
            <template>
              <div class="mt-1">
                <b-button
                  v-if="data.item.links && data.item.links.crmzz"
                  class="btn-icon is-crmzz rounded-circle"
                  style="margin: 0 1px;"
                  @click="openExternalLink(data.item.links.crmzz)"
                >
                  <feather-icon icon="ExternalLinkIcon" />
                </b-button>

                <b-button
                  v-if="data.item.email || data.item.website"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-warning"
                  class="btn-icon rounded-circle"
                  style="margin: 0 1px;"
                  @click="syncCompanyInfo(data.item, 'hunter.io', data)"
                >
                  <b-spinner
                    v-if="data.item.sync"
                    label="Spinning"
                    small
                  />
                  <svg
                    v-else
                    class="dashboard-header__logo"
                    height="15"
                    space="preserve"
                    version="1.1"
                    viewBox="0 0 100 100"
                    width="15"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <metadata id="metadata8">
                      <rdf>
                        <work rdf:about="">
                          <format>image/svg+xml</format>
                          <type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
                        </work>
                      </rdf>
                    </metadata>
                    <defs id="defs6" />
                    <g
                      id="g10"
                      transform="matrix(1.3333333,0,0,-1.3333333,0,116.24)"
                      fill="#ff5722"
                    >
                      <g
                        id="g12"
                        transform="scale(0.1)"
                      >
                        <path
                          id="path26"
                          d="m 537.324,496.277 c -18.015,-5.183 -37.879,-5.269 -56.453,-4.484 -21.605,0.914 -44.484,3.645 -64.433,12.512 -16.415,7.293 -29.583,20.453 -22.692,39.461 4.859,13.406 15.629,25.382 28.078,32.132 13.996,7.594 29.375,6.36 43.379,-0.503 15.723,-7.707 28.801,-20.243 40.656,-32.891 6.215,-6.633 12.145,-13.52 17.996,-20.473 2.7,-3.199 21.008,-21.484 13.469,-25.754 z m 299.461,-6.535 c -27.707,6.446 -60.445,4.137 -88.222,8.578 -43.754,7 -108.926,58.016 -144.254,109.871 -24.547,36.032 -36.262,72.731 -55.028,117.45 -13.097,31.214 -41.656,129.941 -81.593,83.73 -16.446,-19.031 -34.856,-81.68 -48.305,-80.797 -9.961,-2.316 -11.586,17.098 -14.738,25.395 -5.723,15.066 -8.758,32.496 -16.426,46.703 -6.129,11.355 -14.176,24.957 -26.5,30.476 -21.957,11.29 -48.43,-22.738 -61.567,-63.089 C 294.535,746.422 256.773,704.48 240.328,694.336 165.504,648.164 15.3281,599.52 0.0117188,437.543 c -0.0625001,-0.672 0,-2.715 2.5429712,-0.875 C 20.4648,449.637 223.613,595.953 93.2383,313.129 13.3672,139.855 399,29.5117 418.824,29.0625 c 2.672,-0.0586 2.477,1.1563 2.567,1.957 30.773,276.5545 334.781,247.4415 405.578,313.6755 70.16,65.629 71.59,129.157 9.816,145.047"
                          style="stroke:none"
                        />
                      </g>
                    </g>
                  </svg>
                </b-button>

                <!-- <b-button
                  v-if="data.item.email || data.item.website"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-dark"
                  class="btn-icon rounded-circle"
                  style="margin: 0 1px;"
                  @click="syncCompanyInfo(data.item, 'apollo', data)"
                >
                  <b-spinner
                    v-if="data.item.sync"
                    label="Spinning"
                    small
                  />
                  <img
                    v-else
                    width="16"
                    height="16"
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzMxODoyKSI+CjxwYXRoIGQ9Ik0xOS42MjgxIDUuODg5MjZIMTYuNTA3OEwxOC4xMjU0IDguNjkwMzNMMTkuNjI4MSA1Ljg4OTI2WiIgZmlsbD0iI0Y3QzM0MCIvPgo8cGF0aCBkPSJNMjEuMzg3MyAxNy4xMzQ4TDE5LjI3NTcgMTMuNDc2M0wxMi42MjU2IDJMMSAyMi4xMzM5SDcuMjI1OEM4LjA1Nzc0IDIyLjEzMjggOC44NzUwMSAyMS45MTQ3IDkuNTk2OTkgMjEuNTAxNEMxMC4zMTkgMjEuMDg4IDEwLjkyMDcgMjAuNDkzNiAxMS4zNDI5IDE5Ljc3NjhMMTQuNTI1MSAxNC4yMjQ3TDEyLjc1NzIgMTEuMTYyNEw4LjkxNTA5IDE3Ljg1MDhDOC42OTcxIDE4LjIyMTkgOC4zODYxNyAxOC41Mjk3IDguMDEyOTYgMTguNzQzOUM3LjYzOTc0IDE4Ljk1ODIgNy4yMTcxMyAxOS4wNzE1IDYuNzg2NzkgMTkuMDcyNkg2LjMxMzM5TDEyLjYyNjYgOC4xMzU0NUwyMC43MDg3IDIyLjEzMzlIMjNWMTkuOTA1NEwyMS4zOTUyIDE3LjEzNDhIMjEuMzg3M1oiIGZpbGw9ImJsYWNrIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMzE4OjIiPgo8cmVjdCB3aWR0aD0iMjIiIGhlaWdodD0iMjAuMTM2OSIgZmlsbD0id2hpdGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEgMikiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K"
                    alt=""
                  >
                </b-button> -->

                <b-button
                  v-if="data.item.links && data.item.links.facebook"
                  class="btn-icon is-facebook rounded-circle"
                  style="margin: 0 1px;"
                  @click="openExternalLink(data.item.links.facebook)"
                >
                  <feather-icon icon="FacebookIcon" />
                </b-button>

                <b-button
                  v-if="data.item.links && data.item.links.twitter"
                  class="btn-icon is-twitter rounded-circle"
                  style="margin: 0 1px;"
                  @click="openExternalLink(data.item.links.twitter)"
                >
                  <feather-icon icon="TwitterIcon" />
                </b-button>

                <b-button
                  v-if="data.item.links && data.item.links.instagram"
                  class="btn-icon is-instagram rounded-circle"
                  style="margin: 0 1px;"
                  @click="openExternalLink(data.item.links.instagram)"
                >
                  <feather-icon icon="InstagramIcon" />
                </b-button>

                <b-button
                  v-if="data.item.links && data.item.links.linkedin"
                  class="btn-icon is-linkedin rounded-circle"
                  style="margin: 0 1px;"
                  @click="openExternalLink(data.item.links.linkedin)"
                >
                  <feather-icon icon="LinkedinIcon" />
                </b-button>

                <b-button
                  v-if="data.item.links && data.item.links.tiktok"
                  class="btn-icon rounded-circle btn-transparent"
                  style="margin: 0 1px; padding: 0; width: 36px; height: 36px;"
                  @click="openExternalLink(data.item.links.tiktok)"
                >
                  <img
                    style="width: 100%;"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAABNtJREFUaEPNmmnIplMYx39jG/vUCCMi+5aQZZAPllB8sGasESG7suQDXxDZxxYiZJ1J2QYzpHy0LxGhQZQlBmXLkOj3dO6nu/PeyznneZ7pvb5M897nWv73Oee6/td1PzMYj8wBDgDmAtsBWwDrAWsH878DPwFfAJ8ArwOvAt+P6n7GCAYM8ETgZGD3QjtvAY8AjweA2WZKAGwCXAKcAayZ7bFZ4Q/gPuAm4JscmzkAVgXOAa6pHY0cXylr/wRuBK4DlqcopALYFlgI7JxidAxr3gfmAZ/12UoBcDTw0ATfeluMvwGnAE93gegDcGo4m6v0vYkJPf8XOA+4p81+F4CzuhQnFHCb2QuAO5oetgHw2HjmV17Bgba5cyeOAZ6JFzQB2Ap4B1h3mgRfhWEx3CMUwmFoMYDVgDfHnG2WADdHL8MacmzBC3ovVPt/Kt0YwOUhBxfYblV5ADg9enoRcGuhk8tCrRio1wFsDHwKrFVouE2tCcD2wMeFfjxK2wDfxQDmAxcWGu1SawLg+ueBwwr93QJcXAcgMft6jNymHlcbgE3DfduwAITcaTMJYHWEzgduLzCUotIGQF2pt6lRqpIrxnxnBUBaW0qJ+xx3AVDXzHda4D7ejdQdMVvOFcBGgcL20Yq+QHMucZctG56UHfnP2A36BOCx0ugS9Pp2IDaRCkC94wRwGyDXmJQ0AfDsG2iT5ACYL4CXgINHjN5+9xXgQ+BHYN/Qamq2CYA98VfA9cC7ke8cAEsE8HlowkswGPiVwIPAXzUDRwJPhf83AXgD2DM8lx7Y4Mu/BG9raYpMkaUCMIjZKaujNW8DhwPfNujmAChwPVQZ1AF7T1NZjrjN+wC/tCj1ATAFyixHleUlAOTmzn/c8jZZoQByj9AC4Pgo8pUAK+PZ4T7Zz1bHsukOjKtwLnMHlgJbZuzlQSHj1FXsWW1Bm2SSAAaX2IbjkEQAHh/HhfWMYzYxq7TJJAEszi1kTs2czNXlCuDqTABmsN0SX1rXskEh8zw7m0yRJgBXhVrQpm876SiyLh8BO6Q47FkzTwBOls3lKWTub2AdwH8r8U683OHoCODZ2nP9mH5njQhAMjenCjonL5v/X4ucvwAc2hCQFfZAQGeV7AR8MGLwqktH9i5paO4OQ956DGsAN4SJ9cywQ9ILj449bF0c3sZHqgSPE7u7KgC2lJKrlIbeym3j8WWDVyfY9hd+uKgfs2qpgwOrePXhoyRwdaa0lP7RMYfjjhRx+/ZLHYEHg85XZb5+yRlVholhlLHK4tAGWnX7ZPXQNB3VtzDhuf4cqww+T8WZx6GRHD1VrOKOYl7sUPCNO5gdR9rUjfdnOOmLAXiGraq7piII6xxSLQoNzbLAg3YMc59dMm11Lbf52QtoHS2qPJ2Hu05OnB4Opa14SYefnGbjde/Pc/H2dFXfM4F7x7j9paYsgsZyf5OBPvowHT4xndv1IvsACFou83DgQKVvsUTv1zDZmHJsUu5A7HDr8MkpNzuVBK6O2cbPrKbpTknZgcqAldTtlPvLSCch1Yfua1uoyBSfOQAqZbnOpeFipXCnFKByGxOGRC/rByAlAKqAJIA2QyeFIVWuLbOLRfNR4Ang5xSkOWk0x94GwP6hSjr33BxYP/q5jVM3GWz95zY/5DhpWvs/25X0E3J5FJIAAAAASUVORK5CYII="
                    alt=""
                  >
                </b-button>

                <b-button
                  v-if="data.item.links && data.item.links.website"
                  class="btn-icon rounded-circle"
                  style="margin: 0 1px;"
                  variant="danger"
                  @click="openExternalLink(data.item.links.website)"
                >
                  <feather-icon icon="LinkIcon" />
                </b-button>

                <b-button
                  v-if="data.item && data.item.stockName"
                  class="btn-icon rounded-circle"
                  style="margin: 0 1px;"
                  variant="success"
                  @click="openExternalLink(data.item.stockName)"
                >
                  <feather-icon icon="BarChart2Icon" />
                </b-button>
              </div>
            </template>
          </template>

          <template #cell(email)="data">
            <b-button
              v-if="data.item.email"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              v-b-tooltip.hover.v-success
              v-clipboard:copy="data.item.email"
              v-clipboard:success="onCopy"
              variant="gradient-success"
              class="btn-icon rounded-circle"
              :title="data.item.email"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <span v-else>N/A</span>
          </template>

          <template #cell(phone)="data">
            <b-button
              v-if="data.item.phone"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              v-b-tooltip.hover.v-info
              v-clipboard:copy="data.item.phone"
              v-clipboard:success="onCopy"
              variant="gradient-info"
              class="btn-icon rounded-circle"
              :title="data.item.phone"
            >
              <feather-icon icon="PhoneCallIcon" />
            </b-button>
            <span v-else>N/A</span>
          </template>

          <template #cell(created_at)="data">
            <span class="text-center">{{ data.item.date }}</span>
            <br>
            <span class="text-center">{{ data.item.time }}</span>
          </template>

          <template #cell(users)="data">
            <b-avatar-group
              size="34px"
            >
              <template
                v-for="user in data.item.users"
              >
                <b-link
                  :key="user.username"
                  :to="{ name: 'founder-info', params: { username: user.username } }"
                  class="w-34 d-block"
                >
                  <b-avatar
                    v-b-tooltip.hover.bottom="user.name"
                    :src="user.image"
                    class="pull-up"
                  />
                </b-link>
              </template>
            </b-avatar-group>
          </template>

          <template #cell(action)="data">
            <div>
              <b-button
                class="btn-icon is-red rounded-circle"
                variant="danger"
                @click="deleteCompany(data.item)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>

              <b-button
                class="btn-icon is-primary rounded-circle"
                style="margin: 0 1px;"
                variant="primary"
                :to="{ name: 'edit-company', params: { slug: data.item.slug } }"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </div>
          </template>

          <template #cell(slug)="data">
            <b-form-checkbox
              v-model="inviteSelected"
              :value="data.item.id"
              plain
              class="vs-checkbox-con"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                </span>
              </span>
            </b-form-checkbox>
          </template>

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <p class="mt-1">
                <strong>Loading...</strong>
              </p>
            </div>
          </template>

        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="paginationCurrentPage"
                :total-rows="pagination.totalItems || 0"
                :per-page="mainPerPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="paginate"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>

    <div
      v-if="urlPrevImg"
      class="preview-photo"
      :style="{ top: `${pageY}px`, left: `${pageX}px` }"
    >
      <img
        :src="urlPrevImg"
        alt=""
      >
    </div>

  </div>
</template>

<script>
/* eslint-disable */
import { debounce } from 'debounce'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BDropdown, BDropdownItem, BPagination, VBTooltip, BAvatarGroup, BSpinner,
  BFormCheckbox, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapGetters, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import EcommerceStatistics from './analytics/EcommerceStatistics.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BAvatarGroup,
    BFormCheckbox,
    BSpinner,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    BInputGroup,
    BInputGroupAppend,
    EcommerceStatistics,
  },
  setup() {
    return {
      // Filter
      avatarText,
    }
  },
	directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      link: null,
      selected: [],
      loading: false,
      isLoading: false,
      searchQuery: null,
      inviteSelected: [],
      tableColumns: [
        { key: 'show_details', label: 'HU', sortable: false },
        { key: 'id', label: 'SP', sortable: false },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'phone', label: 'Phone', sortable: true },
        { key: 'created_at', label: 'Joined Date', sortable: true },
        { key: 'users', label: 'Representatives', sortable: false },
        // { key: 'links', label: 'Links', sortable: false },
        { key: 'action', label: 'Actions', sortable: false },
        { key: 'slug', label: 'Invite SP', sortable: false },
      ],
      fields: [
        { key: 'name', label: 'name', sortable: false },
        { key: 'email', label: 'email', sortable: false },
        { key: 'phone', label: 'phone', sortable: false },
        { key: 'twitter', label: 'twitter', sortable: false },
        { key: 'linkedin', label: 'linkedin', sortable: false },
        { key: 'position', label: 'position', sortable: false },
        // 'name', 'email', 'phone', 'twitter', 'linkedin', 'position'
      ]
    }
  },
  computed: {
    ...mapGetters('auth', ['hunter']),
    ...mapGetters('companies', ['companies', 'pagination', 'selection']),
    isDesc: {
      get() {
        return this.sort.toLowerCase() === 'desc' ? true : false
      },
      set(value) {
        return value
      },
    },
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions('companies', [
      'getCompaniesAction', 'deleteCompanyAction', 'companySearchAction', 'setCompaniesForSearchPage', 'sendCompanyInvitationAction',
      'syncCompaniesAction',
    ]),
    ...mapActions('settings', ['scrapeCrunchbaseLink']),
    importDataFromLink() {
      this.loading = true
      this.scrapeCrunchbaseLink({ link: this.link }).then(({ data }) => {
        if (data.type && data.type == 'new') {
          const company = data.data
          this.$store.state['companies'].companies.unshift({
            created_at: company.created_at,
            email: company.email,
            huntedUsers: [],
            id: company.id,
            links: {
              crmzz: company.crmzz,
              facebook: company.facebook,
              instagram: company.instagram,
              linkedin: company.linkedin,
              tiktok: company.tiktok,
              twitter: company.twitter,
              website: company.website,
            },
            logo: company.logo ? `https://api.crmzz.com/${company.logo}` : `https://api.crmzz.com/images/companies/brand.png`,
            name: company.name,
            phone: company.phone,
            slug: company.slug,
            stockName: company.stockName,
            sync: false,
            users: null
          })
        }

        this.showToast(data.data.message, 'success')
      }).finally(() => {
        this.loading = false
      })
    },
    syncCompanyInfo(company, service, info) {
      company.sync = true
      this.syncCompaniesAction({
        service,
        slug: company.slug,
        companyId: company.id,
        domain: company.website,
        hasSearch: this.searchQuery ? true : false,
        searchQuery: this.searchQuery
      })
    },
    syncCompanies() {
      if (this.inviteSelected.length) {
        this.$swal({
          title: 'Are you sure?',
          text: `SYNC (${this.inviteSelected.length || 0}) Companies?`,
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Yes, SYNC!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(({ value }) => {
          if (value) {
            this.syncCompaniesAction({ ids: this.inviteSelected })
          }
        })
      } else {
        this.$swal('', 'Please select companies first', 'warning')
      }
    },
    sendCompaniesInvitations() {
      if (this.inviteSelected.length) {
        this.$swal({
          title: 'Are you sure?',
          text: `Send Invitaions to ${this.inviteSelected.length || 0} Companies?`,
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Yes, send invitations!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(({ value }) => {
          if (value) {
            this.sendCompanyInvitationAction({ ids: this.inviteSelected }).finally(() => {
              this.showToast('Company Invitations sent successfully')
              this.inviteSelected = []
            })
          }
        })
      } else {
        this.$swal('', 'Please select companies first', 'warning')
      }
		},
		onRowSelected(items) {
      this.selected = items.map(item => item.id)
    },
    applySelectedCompanies () {
      this.setCompaniesForSearchPage(this.selected).finally(() => {
        this.showToast('Searchable Companies Set Successfully')
      })
    },
    deleteCompany(company) {
			this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(({ value }) => {
        if (value) {
          this.deleteCompanyAction(company.slug).then(() => {
						this.showToast('Company Deleted Successfully!')
					})
        }
      })
    },
    getData() {
      this.isLoading = true
      this.getCompaniesAction(`per_page=${this.mainPerPage}&current_page=${this.paginationCurrentPage}&attribute=${this.attribute}&sort=${this.sort}`).finally(() => {
        this.selected = [...this.selection]
        this.isLoading = false
      })
    },
    searchName: debounce(function (e) {
      if (!e) {
        this.getData()
      } else {
        this.companySearchAction({ key: 'name', value: e })
      }
    }, 500),
    paginate(page) {
      this.isLoading = true
      this.paginationCurrentPage = page
      this.getData()
    },
    sortCompare(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
      if (this.attribute !== key || this.sort !== (sortDesc ? 'DESC' : 'ASC')) {
        this.attribute = key
        this.sort = sortDesc ? 'DESC' : 'ASC'
        this.getData()
        return null
      }
      return false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.w-34 {
    width: 34px;
    margin-left: calc(-5.1px);
}
</style>
